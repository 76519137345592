import React from 'react'
import Img from 'gatsby-image'
import { T } from '../../typo'
import { s, globals, colors, alpha } from '../../style'
import { WrapperSection } from '../../wrappers'
import { ButtonMain } from '../../buttons'

const AboutShort = ({ data, theme, useColor = true }) => {
  const {
    language,
    slug_section,
    titleObject,
    image,
    subtitle,
    description,
    button,
  } = data

  return (
    <WrapperSection id={slug_section} extraCss={{ [s.md]: { marginTop: 0 } }}>
      <div css={[sContainer, sTheme(theme)]}>
        <div css={[sTextContainer, useColor && { color: colors[theme].main }]}>
          <div>
            <div css={sTextContainer.title}>
              <T d={32} m={24} bold condensed variant={titleObject.variant}>
                {titleObject.text}
              </T>
              <T o={0.3} d={14} m={14} mt={1} bold spacious upper>
                {subtitle}
              </T>
            </div>
            <div css={sTextContainer.content} dangerouslySetInnerHTML={{ __html: description }} />
            {/* <T mb={2} m={14} normal dangerouslySetInnerHTML={{ __html: description }} /> */}
          </div>
          <ButtonMain theme={theme} language={language} button={button}>
            {button?.text}
          </ButtonMain>
        </div>
        <div css={sImageContainer}>
          {image?.childImageSharp && (
            <Img
              style={sImgWrapper}
              imgStyle={sImgStyle}
              fluid={image.childImageSharp.fluid}
              alt={image.name}
            />
          )}
        </div>
      </div>
    </WrapperSection>
  )
}

const sContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  [s.sm_down]: {
    flexDirection: 'column',
    boxShadow: globals.shadows.sections.mobile,
    marginLeft: globals.spacing.outside.mobile,
    marginRight: globals.spacing.outside.mobile,
    borderRadius: '32px 32px 20rem 20rem',
  },
  [s.md]: {
    flexDirection: 'row',
    padding: globals.spacing.inside.desktop,
    boxShadow: globals.shadows.sections.desktop,
    margin: '0 auto',
    borderRadius: globals.roundness.sections.desktop,
  },
}

const sTheme = (theme) => ({
  backgroundColor: colors[theme].container.concat(alpha[30]),
})

const sTextContainer = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [s.sm_down]: { width: '100%', padding: globals.spacing.inside.mobile },
  [s.md]: {
    // width: '50%',
    paddingRight: globals.spacing.inside.desktop / 2,
  },

  title: {
    [s.sm_down]: { marginBottom: globals.spacing.inside.mobile },
    [s.md]: { marginBottom: globals.spacing.inside.desktop / 2 },
  },

  content: {
    lineHeight: '2em',

    'ul': {
      marginBottom: '0',
    }
  },
}

const sImageContainer = {
  [s.sm_down]: {
    width: '100%',
    borderRadius: globals.roundness.pictures.mobile,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    paddingBottom: '1rem',
  },
  [s.md]: {
    // width: '50%',
    paddingLeft: globals.spacing.inside.desktop / 2,
    borderRadius: globals.roundness.pictures.desktop,
  },
}

const sImgWrapper = {
  borderRadius: 'inherit',
  boxShadow: globals.shadows.pictures,
  width: 300,
  height: 300,
  borderRadius: '50%',
  '@media screen and maxWidth: 599px': {
    width: 'calc(100vw- 3rem)',
    height: 'calc(100vw- 3rem)',
  },
}

const sImgStyle = {
  objectFit: 'cover',
}

export default AboutShort
